<template>
  <div class="content-box">
    <div class="categ_short_desc blog">
      <p v-if="description">
        {{ description }}
      </p>
      <p v-else>
        Pe langa produsele disponibile in magazinul nostru, sectiunea de Blog vine in
        intampinarea intrebarilor clientilor nostri. Am selectat cele mai frecvente si
        importante intrebari care ne-au fost adresate de-a lungul timpului, iar echipa
        noastra de specialisti a raspuns la ele, cu ajutorul cuvintelor, dar si cu
        ajutorul pozelor sau tutorialelor de mai jos.
      </p>
    </div>
    <mobile-filter />
    <div v-if="posts.length" id="ajax-wrp">
      <div class="ajax-inner">
        <div
          class="blocks-warpper infinite_scroll alte-articole row"
          data-url="load_noutati_ajax"
          id="content"
          ref="scrollComponent"
        >
          <article
            :id="'article_' + post.id"
            class="col-12 col-md-3 block-item blog_noutati categ_2"
            v-for="(post, index) in posts"
            :key="index"
            itemprop="blogPosts"
            itemscope
            itemtype="https://schema.org/BlogPosting"
          >
            <div class="block-item-inner">
              <div class="block-img-wrp">
                <router-link :to="postlink(post.slug)">
                  <img
                    v-if="post.featured_images[0]"
                    :src="post.featured_images[0].path"
                    alt="IMG"
                    itemprop="image"
                  />
                  <img
                    v-else
                    class=""
                    itemprop="image"
                    src="@/assets/images/blog/no-image.png"
                  />
                </router-link>
              </div>
              <div class="art-details">
                <div class="details">
                  <!-- <span>  </span> -->
                  <span>
                    {{ dateformat(post.published_at) }}
                  </span>
                </div>
                <div class="title">
                  <h6>
                    <router-link :to="postlink(post.slug)">{{ post.title }}</router-link>
                  </h6>
                </div>
                <div v-if="windowWidth > 576" class="blog-read-more">
                  <router-link :to="postlink(post.slug)">CITESTE</router-link>
                </div>
              </div>
            </div>
          </article>

          <loading :active="isLoading || (hasFetchedAllData && showSpinner)">
            <spinner :spinner="isLoading"> </spinner>
          </loading>
        </div>

        <!-- <ul class="pagination">
          <li>
            <a
              href="#"
              >&larr; Prev</a
            >
          </li>
          <li class="">
            <a
              href="#"
              >1</a
            >
          </li>
          <li>
            <a
              href="#"
              >Next &rarr;</a
            >
          </li>
        </ul> -->
      </div>
    </div>
    <SkeletonBlogPosts v-if="posts.length === 0" />
  </div>
</template>

<script>
import { blogMixin } from "@/mixins/blogMixin";
import Spinner from "@/components/blocks/Spinner.vue";
// Import component
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import SkeletonBlogPosts from "@/components/blocks/skeleton/blog/SkeletonBlogPosts.vue";
import MobileFilter from "@/components/filter/MobileFilter.vue";

export default {
  name: "BlogArticles",

  props: ["description"],

  components: {
    Loading,
    Spinner,
    SkeletonBlogPosts,
    MobileFilter
  },

  data() {
    return {
      posts: [],
      isLoading: false,
      currentPage: 1,
      totalPages: "",
      showSpinner: true,
      scroll: 0,
      windowWidth: window.innerWidth,
    };
  },

  computed: {
    // posts() {;
    //   return this.$store.getters["blog/posts"];
    // },

    hasFetchedAllData() {
      return this.currentPage === this.totalPages && !this.isLoading;
    },

    selectedCategory() {
      return this.$store.getters["blog/selectedCategory"];
    },
  },

  watch: {
    selectedCategory: function () {
      this.posts = [];
      this.currentPage = 1;
      this.showSpinner = true;
      this.fetchPosts();
    },

    hasFetchedAllData: function () {
      setTimeout(() => (this.showSpinner = false), 2000);
    },
  },

  mixins: [blogMixin],
  methods: {
    async handleScroll() {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {

        if (this.hasFetchedAllData || this.isLoading) {
          return;
        }

        this.currentPage++; 
        this.isLoading = true;
        await this.fetchPosts();
        this.isLoading = false;
      }
    },

    async fetchPosts() {
      try {
        
        await this.$store.dispatch("blog/getPosts", this.currentPage);
        const response = await this.$store.getters["blog/posts"];
        Array.prototype.push.apply(this.posts, response.data);
        this.totalPages = response.last_page;
      } catch (err) {
        console.log(err); // state-of-the-art-error-handling
      }
    },
  },

  // this will register the event when the component is mounted on the DOM
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },

  // We then unregister the listener when the component is removed from the DOM
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },

  created() {
    Array.prototype.push.apply(this.posts, this.$store.getters["blog/posts"].data);
  },
};
</script>

<style scoped>
.categ_short_desc.blog {
  font-size: 14px;
}
</style>
