<template>
  <div class="blg">
    <div itemprop="hasPart" itemscope itemtype="https://schema.org/Blog" id="blog-page">
      <page-header v-if="windowWidth > 576" :item="header_image" />
      <SocialBlog v-if="windowWidth > 576" />
          
      <transition name="fade">
        <nav-filter
          v-if="imgFilter === false || windowWidth > 576"
          :class="{ sticky: scrollNavbar }"
        />
      </transition>
      <div v-show="windowWidth < 576" class="blogs-title container">
        Blog
      </div>
      <div v-if="dataisLoad" class="custom-site-content">
        <div class="container custom-container">
          <div class="row">
            <div class="col-sm-12">
              <div id="noutati">
                <intro-blog :title="categ.name" />
                <mobile-filter v-if="1==2" @click="showMobileFilter" />
                <blog-articles :description="categ.description" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <SkeletonBlog v-if="!dataisLoad" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import NavFilter from "@/components/filter/NavFilter.vue";
import MobileFilter from "@/components/filter/MobileFilter.vue";
import IntroBlog from "@/components/blog/IntroBlog.vue";
import BlogArticles from "@/components/blog/BlogArticles.vue";
import PageHeader from "@/components/page_header/PageHeader.vue";
import SocialBlog from "@/components/blog/SocialBlog.vue";
// import NavbarFilterMobile from "@/components/filter/NavbarFilterMobile.vue";
import SkeletonBlog from "@/components/blocks/skeleton/shop/SkeletonBlog.vue";

import { generalMixin } from "@/mixins/generalMixin";

export default {
  name: "Blog",
  data() {
    return {
      windowWidth: window.innerWidth,
      navbarFilterMobile: false,
      scrollNavbar: false,
      isLoad: false,
      dataisLoad: false,
    };
  },
  components: {
    "nav-filter": NavFilter,
    "intro-blog": IntroBlog,
    "blog-articles": BlogArticles,
    "page-header": PageHeader,
    "mobile-filter": MobileFilter,
    SocialBlog,
    SkeletonBlog,
    // NavbarFilterMobile,
  },
  computed: {
    ...mapGetters("filter", ["imgFilter"]),
    // ...mapMutations("filter", ["HideimgFilter"]),
    header_image() {
      let image = "";

      if (this.categ.header_image) {
        image = this.categ.header_image;
        // console.log(this.categ);
        // console.log(image);
      } else {
        image = this.$store.getters["seo/seo_image"];
        // console.log(image);
      }

      // console.log(image, "image banner");
      return {
        path: image,
      };
    },
    categ() {
      return this.$store.getters["blog/categ"];
    },
    categories() {
      return this.$store.getters["blog/categories"];
    },
    seoParams() {
      return this.$store.getters["blog/seo"];
    },
    blogPostsIsLoad() {
      return this.$store.getters["blog/blogPostsIsLoad"];
    },
  },

  methods: {
    getCategDescription() {
      return this.categ;
    },
    handleScroll() {
      // console.log(window.scrollY);
      if (window.scrollY > 260) {
        this.scrollNavbar = true;
      } else {
        this.scrollNavbar = false;
      }
    },
    showMobileFilter() {
      this.navbarFilterMobile = true;
    },
    closeFilter() {
      this.navbarFilterMobile = false;
      this.$store.dispatch("filter/actionImgFilter", true);
    },
    headerImage() {
      let image = "";

      if (this.isLoad && this.categ.header_image) {
        image = this.categ.header_image;
        // console.log(this.categ);
        // console.log(image);
      } else {
        image = this.$store.getters["seo/seo_image"];
      }

      this.header_image = { path: image };
    },
  },
  mixins: [generalMixin],

  mounted() {
    // this.$store.dispatch("blog/getPosts").then(() => {
    // this.$nextTick(() => {
    //   this.$store.dispatch("shop/actCategoryIsLoad", true);
    // });
    // });
    window.addEventListener("scroll", this.handleScroll);
    this.$store.commit("seo/setParams", this.seoParams);
  },

  created() {
    this.$store.dispatch("blog/getCategories");

    if (this.$route.params.slug) {
      this.$store.dispatch("blog/getCateg", this.$route.params.slug).then(() => {
        this.isLoad = true;
        this.$store.dispatch("blog/getPosts").then(() => {
          this.dataisLoad = true;
        });
      });
    } else {
      this.$store.dispatch("blog/getPosts").then(() => {
        this.dataisLoad = true;
      });
    }
  },

  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
    this.$store.dispatch("shop/actCategoryIsLoad", false);
    this.$store.dispatch("blog/selectedCategory", { id: null, slug: "" });
  },
  beforeRouteUpdate(to) {
    if (to.path === "/blog-seminee") {
      this.$store.dispatch("blog/selectedCategory", { id: null, slug: "" });
    }
    // next();
  },
};
</script>

<style>
@import "../../assets/css/blog.css";
@import "../../assets/css/blogPosts.css";
@import "../../assets/css/mobile-filter.css";
</style>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
