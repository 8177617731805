<template >
  <h1 v-if="title">{{ title }}</h1>
  <h1 v-else>Blog - Seminee Pefoc.ro</h1>
</template>

<script>
export default {
  name: "IntroBlog",
  props: ['title'],
};
</script>