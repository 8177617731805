<template>
  <div class="social-blog wrp-share-buttons">
    <div class="container">
      <a
        target="_blank"
        class=""
        href="https://www.youtube.com/c/PefocRo-seminee-pe-lemne-moderne-la-preturi-mici"
      >
        <!-- <font-awesome-icon :icon="['fab', 'youtube']" /> -->
        <img 
              width="36"
              height="36"
              loading="lazy" 
              src="@/assets/images/blog/youtube-blog.png" 
              alt="youtube"
            />
      </a>
      
      <a target="_blank" href="https://www.facebook.com/pefoc.ro/">
        <!-- <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'facebook-square' }" /> -->
        <img 
              width="36"
              height="36"
              loading="lazy" 
              src="@/assets/images/blog/facebook-blog.png" 
              alt="facebook"
            />
      </a>

      <a target="_blank" href="https://www.instagram.com/pefoc.ro/?hl=en">
        <img 
              width="36"
              height="36"
              loading="lazy" 
              src="@/assets/images/blog/instagram-blog.png" 
              alt="instagram"
            />
      </a>

      <a target="_blank" href="https://www.pinterest.com/semineepelemne/seminee-pe-lemne/">
        <!-- <font-awesome-icon :icon="['fab', 'instagram']" /> -->
        <img 
              width="36"
              height="36"
              loading="lazy" 
              src="@/assets/images/blog/pinterest-blog.png" 
              alt="pinterest"
            />
      </a>

      <a target="_blank" href="https://www.tiktok.com/@pefoc.ro">
        <img 
              width="36"
              height="36"
              loading="lazy" 
              src="@/assets/images/blog/tiktok-blog.png" 
              alt="tiktok"
            />
      </a>

      <a target="_blank" href="https://www.linkedin.com/company/pefoc">
        <img 
              width="36"
              height="36"
              loading="lazy" 
              src="@/assets/images/blog/linkedin-blog.png" 
              alt="linkedin"
            />
      </a>
    </div>
  </div>
</template>

<style scoped>
.wrp-share-buttons a svg {
  color: white;
  font-size: 20px;
}

.wrp-share-buttons a svg.fa-facebook-square:hover {
  color: #3b5998;
}

.wrp-share-buttons a svg.fa-youtube:hover {
  color: #dd4b39;
}

.wrp-share-buttons a svg.fa-instagram:hover {
  color: #c4302b;
}

.wrp-share-buttons a svg.fa-twitter:hover {
  color: #1da1f2;
}

.wrp-share-buttons a svg.fa-google:hover {
  color: #dd4b39;
}

.social-blog {
  position: absolute;
  top: 300px;
  width: 100%;
}

.social-blog a {
  padding-right: 20px;
}

@media (max-width: 1200px) {
  .social-blog {
    top: 250px;
  }
}

@media (max-width: 991px) {
  .social-blog {
    top: 150px;
  }
}

@media (max-width: 768px) {
  .social-blog {
    text-align: center;
  }
}
</style>
